import { SET_COINS } from '../actions/coins.actions';

const initialState = {
	coins: 0,
};

const coinsReducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_COINS:
		return {
			...state,
			coins: action.payload
		};
	default:
		return state;
	}
};

export default coinsReducer;