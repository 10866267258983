const GET_USER_AVATAR_DATA = 'GET_USER_AVATAR_DATA';
const SET_USER_AVATAR_DATA = 'SET_USER_AVATAR_DATA';
const START_LOADING_USER_AVATAR = 'START_LOADING_USER_AVATAR';
const STOP_LOADING_USER_AVATAR = 'STOP_LOADING_USER_AVATAR';
const BUY_ITEM = 'BUY_ITEM';
const START_LOADING_BUY_ITEM = 'START_LOADING_BUY_ITEM';
const STOP_LOADING_BUY_ITEM = 'STOP_LOADING_BUY_ITEM';
const SAVE_USED_AVATAR_ITEMS = 'SAVE_USED_AVATAR_ITEMS';
const START_LOADING_SAVE_USED_AVATAR_ITEMS = 'START_LOADING_SAVE_USED_AVATAR_ITEMS';
const STOP_LOADING_SAVE_USED_AVATAR_ITEMS = 'STOP_LOADING_SAVE_USED_AVATAR_ITEMS';
const SET_ITEM_TO_BUY = 'SET_ITEM_TO_BUY';

const getUserAvatarData = (userId) => ({
	type: GET_USER_AVATAR_DATA,
	payload: userId
});

const setUserAvatarData = (payload) => ({
	type: SET_USER_AVATAR_DATA,
	payload
});

const setItemToBuy = (payload) => ({
	type: SET_ITEM_TO_BUY,
	payload
});

const startLoadingUserAvatarData = () => ({
	type: START_LOADING_USER_AVATAR
});

const stopLoadingUserAvatarData = () => ({
	type: STOP_LOADING_USER_AVATAR
});

const buyItem = (payload) => ({
	type: BUY_ITEM,
	payload
});

const saveUsedAvatarItems = (payload) => ({
	type: SAVE_USED_AVATAR_ITEMS,
	payload
});

const startLoadingBuyItem = () => ({
	type: START_LOADING_BUY_ITEM
});

const stopLoadingBuyItem = () => ({
	type: STOP_LOADING_BUY_ITEM
});

const startLoadingSaveUsedAvatarItems = () => ({
	type: START_LOADING_SAVE_USED_AVATAR_ITEMS
});

const stopLoadingSaveUsedAvatarItems = () => ({
	type: STOP_LOADING_SAVE_USED_AVATAR_ITEMS
});

export {
	GET_USER_AVATAR_DATA,
	SET_USER_AVATAR_DATA,
	START_LOADING_USER_AVATAR,
	STOP_LOADING_USER_AVATAR,
	BUY_ITEM,
	START_LOADING_BUY_ITEM,
	STOP_LOADING_BUY_ITEM,
	SAVE_USED_AVATAR_ITEMS,
	START_LOADING_SAVE_USED_AVATAR_ITEMS,
	STOP_LOADING_SAVE_USED_AVATAR_ITEMS,
	SET_ITEM_TO_BUY,
	getUserAvatarData,
	setUserAvatarData,
	startLoadingUserAvatarData,
	stopLoadingUserAvatarData,
	buyItem,
	startLoadingBuyItem,
	stopLoadingBuyItem,
	saveUsedAvatarItems,
	startLoadingSaveUsedAvatarItems,
	stopLoadingSaveUsedAvatarItems,
	setItemToBuy
};