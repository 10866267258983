import {
	SET_ITEM_TO_BUY,
	SET_USER_AVATAR_DATA,
	START_LOADING_BUY_ITEM,
	START_LOADING_SAVE_USED_AVATAR_ITEMS,
	START_LOADING_USER_AVATAR,
	STOP_LOADING_BUY_ITEM,
	STOP_LOADING_SAVE_USED_AVATAR_ITEMS,
	STOP_LOADING_USER_AVATAR
} from '../actions/avatar.actions';

const initialState = {
	isLoading: false,
	isLoadingBuyItem: false,
	isLoadingSavingUsedAvatarItems: false,
	avatarData: null,
	itemToBuy: null,
};

const avatarReducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_USER_AVATAR_DATA:
		return {
			...state,
			avatarData: action.payload
		};
	case SET_ITEM_TO_BUY:
		return {
			...state,
			itemToBuy: action.payload
		};
	case START_LOADING_USER_AVATAR:
		return {
			...state,
			isLoading: true
		};
	case STOP_LOADING_USER_AVATAR:
		return {
			...state,
			isLoading: false
		};
	case START_LOADING_BUY_ITEM:
		return {
			...state,
			isLoadingBuyItem: true
		};
	case STOP_LOADING_BUY_ITEM:
		return {
			...state,
			isLoadingBuyItem: false
		};
	case START_LOADING_SAVE_USED_AVATAR_ITEMS:
		return {
			...state,
			isLoadingSavingUsedAvatarItems: true
		};
	case STOP_LOADING_SAVE_USED_AVATAR_ITEMS:
		return {
			...state,
			isLoadingSavingUsedAvatarItems: false
		};
	default:
		return state;
	}
};

export default avatarReducer;