import {
	SET_LOGGED_USER,
	SET_PROFILE_DATA,
	SET_USERS_GAME_DATA,
	START_LOADING_USERS,
	STOP_LOADING_USERS,
	START_LOGIN_LOADING,
	STOP_LOGIN_LOADING,
	SET_LOGGED_USER_DAILY_VOTES,
	START_LOADING_USERS_GAME_DATA,
	STOP_LOADING_USERS_GAME_DATA,
	SET_USER_MISSIONS,
} from '../actions/users.actions';

const initialState = {
	isLoading: true,
	isLoginLoading: true,
	isUsersGameDataLoading: true,
	loggedUser: null,
	profileData: null,
	gameData: null,
};

const usersReducer = (state = initialState, action) => {
	switch (action.type) {
	case SET_PROFILE_DATA:
		return {
			...state,
			profileData: action.payload,
		};
	case SET_LOGGED_USER:
		return {
			...state,
			loggedUser: action.payload,
		};
	case SET_LOGGED_USER_DAILY_VOTES:
		return {
			...state,
			loggedUser: {
				...state.loggedUser,
				votes: {
					...state.loggedUser.votes,
					dailyVotes: action.payload
				}
			},
		};
	case START_LOGIN_LOADING:
		return {
			...state,
			isLoginLoading: true,
		};
	case STOP_LOGIN_LOADING:
		return {
			...state,
			isLoginLoading: false,
		};
	case START_LOADING_USERS:
		return {
			...state,
			isLoading: true,
		};
	case STOP_LOADING_USERS:
		return {
			...state,
			isLoading: false,
		};
	case SET_USERS_GAME_DATA:
		return {
			...state,
			gameData: action.payload
		};
	case START_LOADING_USERS_GAME_DATA:
		return {
			...state,
			isUsersGameDataLoading: true,
		};
	case STOP_LOADING_USERS_GAME_DATA:
		return {
			...state,
			isUsersGameDataLoading: false,
		};
	case SET_USER_MISSIONS:
		return {
			...state,
			gameData: {
				...state.gameData,
				missions: {
					...state.gameData.missions,
					[action.payload.mission]: {
						...state.gameData.missions[action.payload.mission],
						taken: true
					}
				}
			},
		};
	default:
		return state;
	}
};

export default usersReducer;
