/* eslint-disable max-len */

import React from 'react';

export const TournamentFormats = {
	LEAGUE: 'League',
	CUP: 'Cup',
	GROUPS_AND_KNOCKOUT: 'GroupsAndKnockout',
	FRIENDLIES: 'FRIENDLIES',
	ELIMINATIONS: 'ELIMINATIONS',
};

export const TOURNAMENTS = [
	// LEAGUES
	{
		tournamentId: 40,
		tournamentName: 'Championship',
		country: 'England',
		tournamentNameBg: 'Чемпиъншип',
		countryBg: 'Англия',
		rank: 11,
		countryAbreviation: 'GB',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 39,
		tournamentName: 'Premier League',
		country: 'England',
		tournamentNameBg: 'Висша Лига',
		countryBg: 'Англия',
		rank: 5,
		countryAbreviation: 'GB',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 172,
		tournamentName: 'A PFG',
		country: 'Bulgaria',
		tournamentNameBg: 'А Група',
		countryBg: 'България',
		rank: 4,
		countryAbreviation: 'BG',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 135,
		tournamentName: 'Serie A',
		country: 'Italy',
		tournamentNameBg: 'Серия А',
		countryBg: 'Италия',
		rank: 6,
		countryAbreviation: 'IT',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 140,
		tournamentName: 'Primera Division',
		country: 'Spain',
		tournamentNameBg: 'Ла Лига',
		countryBg: 'Испания',
		rank: 7,
		countryAbreviation: 'ES',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 78,
		tournamentName: 'Bundesliga 1',
		country: 'Germany',
		tournamentNameBg: 'Бундеслига',
		countryBg: 'Германия',
		rank: 8,
		countryAbreviation: 'DE',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 61,
		tournamentName: 'Ligue 1',
		country: 'France',
		tournamentNameBg: 'Лига 1',
		countryBg: 'Франция',
		rank: 9,
		countryAbreviation: 'FR',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 94,
		tournamentName: 'Premiera Liga',
		country: 'Portugal',
		tournamentNameBg: 'Примейра Лига',
		countryBg: 'Португалия',
		rank: 12,
		countryAbreviation: 'PT',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 88,
		tournamentName: 'Eredivisie',
		country: 'Netherlands',
		tournamentNameBg: 'Ередивизи',
		countryBg: 'Нидерландия',
		rank: 12,
		countryAbreviation: 'NL',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 235,
		tournamentName: 'Premier League',
		country: 'Russia',
		tournamentNameBg: 'Премиер Лига',
		countryBg: 'Русия',
		rank: 13,
		countryAbreviation: 'RU',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 144,
		tournamentName: 'Jupiler Pro League',
		country: 'Belgium',
		tournamentNameBg: 'Първа Дивизия А',
		countryBg: 'Белгия',
		rank: 13,
		countryAbreviation: 'BE',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 218,
		tournamentName: 'Tipp3 Bundesliga',
		country: 'Austria',
		tournamentNameBg: 'Бундеслига',
		countryBg: 'Австрия',
		rank: 13,
		countryAbreviation: 'AT',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 179,
		tournamentName: 'Premiership',
		country: 'Scotland',
		tournamentNameBg: 'Премиършип',
		countryBg: 'Шотландия',
		rank: 13,
		countryAbreviation: 'SCT',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 333,
		tournamentName: 'Premier League',
		country: 'Ukraine',
		tournamentNameBg: 'Премиер Лига',
		countryBg: 'Украйна',
		rank: 13,
		countryAbreviation: 'UA',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 203,
		tournamentName: 'Super Lig',
		country: 'Turkey',
		tournamentNameBg: 'Супер Лига',
		countryBg: 'Турция',
		rank: 13,
		countryAbreviation: 'TR',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 119,
		tournamentName: 'Superligaen',
		country: 'Denmark',
		tournamentNameBg: 'Суперлига',
		countryBg: 'Дания',
		rank: 13,
		countryAbreviation: 'DK',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	// {
	//   tournamentId: 318,
	//   tournamentName: '1. Division',
	//   country: 'Cyprus',
	//   tournamentNameBg: 'Първа Дивизия',
	//   countryBg: 'Кипър',
	//   rank: 4,
	//   countryAbreviation: 'CY',
	//   format: TournamentFormats.LEAGUE
	// },
	{
		tournamentId: 286,
		tournamentName: 'Super Liga',
		country: 'Serbia',
		tournamentNameBg: 'Суперлига',
		countryBg: 'Сърбия',
		rank: 13,
		countryAbreviation: 'RS',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 207,
		tournamentName: 'Super League',
		country: 'Switzerland',
		tournamentNameBg: 'Суперлига',
		countryBg: 'Швейцария',
		rank: 13,
		countryAbreviation: 'CH',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 345,
		tournamentName: 'Czech Liga',
		country: 'Czech-Republic',
		tournamentNameBg: 'Първа Лига',
		countryBg: 'Чехия',
		rank: 13,
		countryAbreviation: 'CZ',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 210,
		tournamentName: 'Prva HNL',
		country: 'Croatia',
		tournamentNameBg: 'Първа Лига',
		countryBg: 'Хърватия',
		rank: 13,
		countryAbreviation: 'HR',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 197,
		tournamentName: 'Super League',
		country: 'Greece',
		tournamentNameBg: 'Супер Лига',
		countryBg: 'Гърция',
		rank: 13,
		countryAbreviation: 'GR',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 383,
		// eslint-disable-next-line quotes
		tournamentName: `Ligat ha'Al`,
		country: 'Israel',
		tournamentNameBg: 'Висша Лига',
		countryBg: 'Израел',
		rank: 13,
		countryAbreviation: 'IL',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 103,
		tournamentName: 'Eliteserien',
		country: 'Norway',
		tournamentNameBg: 'Елитсериен',
		countryBg: 'Норвегия',
		rank: 13,
		countryAbreviation: 'NO',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 113,
		tournamentName: 'Allsvenskan',
		country: 'Sweden',
		tournamentNameBg: 'Алсвенскан Лига',
		countryBg: 'Швеция',
		rank: 13,
		countryAbreviation: 'SE',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 283,
		tournamentName: 'Liga 1',
		country: 'Romania',
		tournamentNameBg: 'Лига I',
		countryBg: 'Румъния',
		rank: 13,
		countryAbreviation: 'RO',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 419,
		tournamentName: 'Premyer Liqa',
		country: 'Azerbaidjan',
		tournamentNameBg: 'Висша Лига',
		countryBg: 'Азербайджан',
		rank: 14,
		countryAbreviation: 'AZ',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 389,
		tournamentName: 'Premier League',
		country: 'Kazakhstan',
		tournamentNameBg: 'Висша Лига',
		countryBg: 'Казахстан',
		rank: 14,
		countryAbreviation: 'KZ',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 271,
		tournamentName: 'NB I',
		country: 'Hungary',
		tournamentNameBg: 'НП I',
		countryBg: 'Унгария',
		rank: 14,
		countryAbreviation: 'HU',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 116,
		tournamentName: 'Vysshaya Liga',
		country: 'Belarus',
		tournamentNameBg: 'Висша Лига',
		countryBg: 'Беларус',
		rank: 14,
		countryAbreviation: 'BY',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 106,
		tournamentName: 'Ekstraklasa',
		country: 'Poland',
		tournamentNameBg: 'Екстракласа',
		countryBg: 'Полша',
		rank: 13,
		countryAbreviation: 'PL',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 41,
		tournamentName: 'League One',
		country: 'England',
		tournamentNameBg: 'Първа Лига',
		countryBg: 'Англия',
		rank: 14,
		countryAbreviation: 'GB',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 42,
		tournamentName: 'League Two',
		country: 'England',
		tournamentNameBg: 'Втора Лига',
		countryBg: 'Англия',
		rank: 14,
		countryAbreviation: 'GB',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 43,
		tournamentName: 'National League',
		country: 'England',
		tournamentNameBg: 'Национална Лига',
		countryBg: 'Англия',
		rank: 15,
		countryAbreviation: 'GB',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 173,
		tournamentName: 'B PFG',
		country: 'Bulgaria',
		tournamentNameBg: 'Б Група',
		countryBg: 'България',
		rank: 11,
		countryAbreviation: 'BG',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 141,
		tournamentName: 'Segunda Division',
		country: 'Spain',
		tournamentNameBg: 'Ла Лига 2',
		countryBg: 'Испания',
		rank: 14,
		countryAbreviation: 'ES',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 136,
		tournamentName: 'Serie B',
		country: 'Italy',
		tournamentNameBg: 'Серия Б',
		countryBg: 'Италия',
		rank: 14,
		countryAbreviation: 'IT',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 62,
		tournamentName: 'Ligue 2',
		country: 'France',
		tournamentNameBg: 'Лига 2',
		countryBg: 'Франция',
		rank: 14,
		countryAbreviation: 'FR',
		format: TournamentFormats.LEAGUE,
	},
	{
		tournamentId: 79,
		tournamentName: 'Bundesliga 2',
		country: 'Germany',
		tournamentNameBg: 'Бундеслига 2',
		countryBg: 'Германия',
		rank: 14,
		countryAbreviation: 'DE',
		format: TournamentFormats.LEAGUE,
	},
	// CUPS
	{
		tournamentId: 174,
		tournamentName: 'Cup',
		country: 'Bulgaria',
		tournamentNameBg: 'Купа на България',
		countryBg: 'България',
		rank: 9,
		countryAbreviation: 'BG',
		format: TournamentFormats.CUP,
	},
	{
		tournamentId: 81,
		tournamentName: 'DFB Pokal',
		country: 'Germany',
		tournamentNameBg: 'Купа на Германия',
		countryBg: 'Германия',
		rank: 9,
		countryAbreviation: 'DE',
		format: TournamentFormats.CUP,
	},
	{
		tournamentId: 137,
		tournamentName: 'Coppa Italia',
		country: 'Italy',
		tournamentNameBg: 'Купа на Италия',
		countryBg: 'Италия',
		rank: 9,
		countryAbreviation: 'IT',
		format: TournamentFormats.CUP,
	},
	{
		tournamentId: 66,
		tournamentName: 'Coupe de France',
		country: 'France',
		tournamentNameBg: 'Купа на Франция',
		countryBg: 'Франция',
		rank: 9,
		countryAbreviation: 'FR',
		format: TournamentFormats.CUP,
	},
	{
		tournamentId: 143,
		tournamentName: 'Copa del Rey',
		country: 'Spain',
		tournamentNameBg: 'Купа на Испания',
		countryBg: 'Испания',
		rank: 9,
		countryAbreviation: 'ES',
		format: TournamentFormats.CUP,
	},
	{
		tournamentId: 45,
		tournamentName: 'FA Cup',
		country: 'England',
		tournamentNameBg: 'ФА Къп',
		countryBg: 'Англия',
		rank: 8,
		countryAbreviation: 'GB',
		format: TournamentFormats.CUP,
	},
	{
		tournamentId: 46,
		tournamentName: 'EFL Trophy',
		country: 'England',
		tournamentNameBg: 'Трофей на ФЛ',
		countryBg: 'Англия',
		rank: 11,
		countryAbreviation: 'GB',
		format: TournamentFormats.CUP,
	},
	{
		tournamentId: 48,
		tournamentName: 'League Cup',
		country: 'England',
		tournamentNameBg: 'Карабао Къп',
		countryBg: 'Англия',
		rank: 8,
		countryAbreviation: 'GB',
		format: TournamentFormats.CUP,
	},
	// Super Cups
	{
		tournamentId: 556,
		tournamentName: 'Super Cup',
		country: 'Spain',
		format: TournamentFormats.ELIMINATIONS,
		tournamentNameBg: 'Суперкупа',
		countryBg: 'Испания',
		rank: 3,
		countryAbreviation: 'ES',
	},
	{
		tournamentId: 547,
		tournamentName: 'Super Cup',
		country: 'Italy',
		format: TournamentFormats.ELIMINATIONS,
		tournamentNameBg: 'Суперкупа',
		countryBg: 'Италия',
		rank: 3,
		countryAbreviation: 'IT',
	},
	{
		tournamentId: 656,
		tournamentName: 'Super Cup',
		country: 'Bulgaria',
		format: TournamentFormats.ELIMINATIONS,
		tournamentNameBg: 'Суперкупа',
		countryBg: 'България',
		rank: 2,
		countryAbreviation: 'BG',
	},
	{
		tournamentId: 528,
		tournamentName: 'Super Cup',
		country: 'England',
		format: TournamentFormats.ELIMINATIONS,
		tournamentNameBg: 'Къмюнити Шийлд',
		countryBg: 'Англия',
		rank: 3,
	},
	{
		tournamentId: 526,
		tournamentName: 'Super Cup',
		country: 'France',
		format: TournamentFormats.ELIMINATIONS,
		tournamentNameBg: 'Суперкупа',
		countryBg: 'Франция',
		rank: 3,
	},
	{
		tournamentId: 529,
		tournamentName: 'Super Cup',
		country: 'Germany',
		format: TournamentFormats.ELIMINATIONS,
		tournamentNameBg: 'Суперкупа',
		countryBg: 'Германия',
		rank: 3,
	},
	// Groups and Knockout
	{
		tournamentId: 531,
		tournamentName: 'UEFA Super Cup',
		country: 'Europe',
		format: TournamentFormats.ELIMINATIONS,
		tournamentNameBg: 'Суперкупа на Европа',
		countryBg: 'Европа',
		rank: 1,
	},
	{
		tournamentId: 2,
		tournamentName: 'UEFA Champions League',
		country: 'Europe',
		tournamentNameBg: 'Шампионска Лига',
		countryBg: 'Европа',
		rank: 1,
		countryAbreviation: 'EU',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 3,
		tournamentName: 'UEFA Europa League',
		country: 'Europe',
		tournamentNameBg: 'Лига Европа',
		countryBg: 'Европа',
		rank: 2,
		countryAbreviation: 'EU',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 848,
		tournamentName: 'UEFA Conference League',
		country: 'Europe',
		tournamentNameBg: 'Лига на Конф.',
		countryBg: 'Европа',
		rank: 3,
		countryAbreviation: 'EU',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
	  tournamentId: 4,
	  tournamentName: 'European Championship',
	  country: 'Europe',
	  tournamentNameBg: 'Европейско първенство',
	  countryBg: 'Европа',
	  rank: 3,
	  countryAbreviation: 'EU',
	  format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
	  tournamentId: 9,
	  tournamentName: 'Copa America',
	  country: 'South America',
	  tournamentNameBg: 'Копа Америка',
	  countryBg: 'Южна Америка',
	  rank: 3,
	  countryAbreviation: 'SA',
	  format: TournamentFormats.GROUPS_AND_KNOCKOUT
	},
	{
		tournamentId: 1,
		tournamentName: 'World Cup',
		country: 'World',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Световно Първенство',
		countryBg: 'Международни',
		rank: 1,
		countryAbreviation: 'WORLD',
	},
	{
		tournamentId: 5,
		tournamentName: 'UEFA Nations League',
		country: 'Europe',
		tournamentNameBg: 'Лига на нациите',
		countryBg: 'Европа',
		rank: 3,
		countryAbreviation: 'EU',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	{
		tournamentId: 6,
		tournamentName: 'Africa Cup of Nations',
		country: 'Africa',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Купа на aфр. нации',
		countryBg: 'Международни',
		rank: 10,
		countryAbreviation: 'AF',
	},
	{
		tournamentId: 7,
		tournamentName: 'Asian Cup',
		country: 'Asia',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Купа на Азия',
		countryBg: 'Международни',
		rank: 10,
		countryAbreviation: 'AS',
	},
	{
		tournamentId: 13,
		tournamentName: 'CONMEBOL Libertadores',
		country: 'South America',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Копа Либертадорес',
		countryBg: 'Ю. Америка',
		rank: 10,
		countryAbreviation: 'WORLD',
	},
	{
		tournamentId: 11,
		tournamentName: 'CONMEBOL Sudamericana',
		country: 'South America',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Копа Судамерикана',
		countryBg: 'Ю. Америка',
		rank: 10,
		countryAbreviation: 'WORLD',
	},
	// {
	// 	tournamentId: 32,
	// 	tournamentName: 'World Cup - Qualification Europe',
	// 	country: 'Europe',
	// 	tournamentNameBg: 'СП квалиф.',
	// 	countryBg: 'Европа',
	// 	rank: 3,
	// 	countryAbreviation: 'EU',
	// 	format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	// },
	// {
	// 	tournamentId: 34,
	// 	tournamentName: 'World Cup - Qualification South America',
	// 	country: 'South America',
	// 	tournamentNameBg: 'СП квалиф.',
	// 	countryBg: 'Ю. Америка',
	// 	rank: 4,
	// 	countryAbreviation: 'SOUTH AMERICA',
	// 	format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	// },
	// {
	// 	tournamentId: 29,
	// 	tournamentName: 'World Cup - Qualification Africa',
	// 	country: 'Africa',
	// 	tournamentNameBg: 'СП квалиф.',
	// 	countryBg: 'Африка',
	// 	rank: 5,
	// 	countryAbreviation: 'AFRICA',
	// 	format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	// },
	{
		tournamentId: 30,
		tournamentName: 'World Cup - Qualification Asia',
		country: 'Asia',
		tournamentNameBg: 'СП квалиф.',
		countryBg: 'Азия',
		rank: 6,
		countryAbreviation: 'ASIA',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	},
	// {
	// 	tournamentId: 31,
	// 	tournamentName: 'World Cup - Qualification CONCACAF',
	// 	country: 'North America',
	// 	tournamentNameBg: 'СП квалиф.',
	// 	countryBg: 'КОНКАКАФ',
	// 	rank: 6,
	// 	countryAbreviation: 'CONCACAF',
	// 	format: TournamentFormats.GROUPS_AND_KNOCKOUT,
	// },
	// NOT SURE IF THIS EVEN EXISTS
	// {
	//   tournamentId: 33,
	//   tournamentName: 'World Cup - Qualification Oceania',
	//   country: 'Oceania',
	//   tournamentNameBg: 'СП квалификации - Океания',
	//   countryBg: 'Океания',
	//   rank: 6,
	//   countryAbreviation: 'OCEANIA',
	//   format: TournamentFormats.GROUPS_AND_KNOCKOUT
	// },
	// Friendlies
	{

		tournamentId: 960,
		tournamentName: 'Euro Championship - Qualification',
		country: 'Europe',
		tournamentNameBg: 'Евро 2024 - Квалиф.',
		countryBg: 'Европа',
		rank: 1,
		countryAbreviation: 'EU',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		coverage: {
			fixtures: {
				events: true,
				lineups: true,
				statisticsFixtures: true,
				statisticsPlayers: true
			},
			standings: true,
			players: false,
			topScorers: false,
			topAssists: false,
			topCards: false,
			injuries: false,
			predictions: true,
			odds: true
		}
	},
	{
		tournamentId: 10,
		tournamentName: 'Friendlies',
		country: 'World',
		tournamentNameBg: 'Межд. приятелски',
		countryBg: 'Международни',
		rank: 16,
		countryAbreviation: 'WORLD',
		format: TournamentFormats.FRIENDLIES,
	},
	{
		tournamentId: 667,
		tournamentName: 'Friendlies Clubs',
		country: 'World',
		tournamentNameBg: 'Приятелски',
		countryBg: 'Клубни',
		rank: 16,
		countryAbreviation: 'WORLD',
		format: TournamentFormats.FRIENDLIES,
	},

	// Added before summer 2022
	{
		tournamentId: 253,
		tournamentName: 'Major League Soccer',
		country: 'USA',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'МЛС',
		countryBg: 'САЩ',
		rank: 14,
		countryAbreviation: 'US',
	},
	{
		tournamentId: 281,
		tournamentName: 'Primera Division',
		country: 'Peru',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Лига 1',
		countryBg: 'Перу',
		rank: 14,
		countryAbreviation: 'PE',
	},
	{
		tournamentId: 71,
		tournamentName: 'Serie A',
		country: 'Brazil',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Серия А',
		countryBg: 'Бразилия',
		rank: 14,
		countryAbreviation: 'BR',
	},
	{
		tournamentId: 128,
		tournamentName: 'Primera Division',
		country: 'Argentina',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Л. Професионал',
		countryBg: 'Аржентина',
		rank: 14,
		countryAbreviation: 'AR',
	},
	{
		tournamentId: 344,
		tournamentName: 'Primera División',
		country: 'Bolivia',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Примера Дивисион',
		countryBg: 'Боливия',
		rank: 14,
		countryAbreviation: 'BO',
	},
	{
		tournamentId: 239,
		tournamentName: 'Primera A',
		country: 'Colombia',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Примера А',
		countryBg: 'Колумбия',
		rank: 14,
		countryAbreviation: 'CO',
	},
	{
		tournamentId: 265,
		tournamentName: 'Primera Division',
		country: 'Chile',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Примера Дивисион',
		countryBg: 'Чили',
		rank: 14,
		countryAbreviation: 'CL',
	},
	{
		tournamentId: 299,
		tournamentName: 'Primera Division',
		country: 'Venezuela',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Примера Дивисион',
		countryBg: 'Венецуела',
		rank: 14,
		countryAbreviation: 'VE',
	},
	{
		tournamentId: 242,
		tournamentName: 'Serie A',
		country: 'Ecuador',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT, 
		tournamentNameBg: 'Серия А',
		countryBg: 'Еквадор',
		rank: 14,
		countryAbreviation: 'EC',
	},
	{
		tournamentId: 268,
		tournamentName: 'Primera Division - Apertura',
		country: 'Uruguay',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Примера Дивисион',
		countryBg: 'Уругвай',
		rank: 14,
		countryAbreviation: 'UY',
	},
	{
		tournamentId: 250,
		tournamentName: 'Primera Division - Apertura',
		country: 'Paraguay',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Примера Дивисион',
		countryBg: 'Парагвай',
		rank: 14,
		countryAbreviation: 'PA',
	},
	{
		tournamentId: 98,
		tournamentName: 'J. League Div.1',
		country: 'Japan',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Джей 1 Лига',
		countryBg: 'Япония',
		rank: 15,
		countryAbreviation: 'JP',
	},
	{
		tournamentId: 292,
		tournamentName: 'K-League Classic',
		country: 'South-Korea',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'К-Лига 1',
		countryBg: 'Южна Корея',
		rank: 15,
		countryAbreviation: 'KR',
	},
	{
		tournamentId: 357,
		tournamentName: 'Premier League',
		country: 'Ireland',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Висша Лига',
		countryBg: 'Ирландия',
		rank: 14,
		countryAbreviation: 'IE',
	},
	{
		tournamentId: 364,
		tournamentName: '1. Liga',
		country: 'Latvia',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Вирслига',
		countryBg: 'Латвия',
		rank: 14,
		countryAbreviation: 'LV',
	},
	{
		tournamentId: 913,
		tournamentName: 'Finalissima',
		country: 'World',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Финалисима',
		countryBg: 'Международни',
		rank: 1,
		countryAbreviation: 'WORLD',
	},
	{
		tournamentId: 199,
		tournamentName: 'Cup',
		country: 'Greece',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Купа на Гърция',
		countryBg: 'Гърция',
		rank: 12,
		countryAbreviation: 'GR',
	},
	{
		tournamentId: 543,
		tournamentName: 'Super Cup',
		country: 'Netherlands',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Суперкупа на Нидерландия',
		countryBg: 'Нидерландия',
		rank: 12,
		countryAbreviation: 'NL',
	},
	{
		tournamentId: 90,
		tournamentName: 'KNVB Beker',
		country: 'Netherlands',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Купа на Нидерландия',
		countryBg: 'Нидерландия',
		rank: 12,
		countryAbreviation: 'NL',
	},
	{
		tournamentId: 206,
		tournamentName: 'Cup',
		country: 'Turkey',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Купа на Турция',
		countryBg: 'Турция',
		rank: 12,
		countryAbreviation: 'TR',
	},
	{
		tournamentId: 551,
		tournamentName: 'Super Cup',
		country: 'Turkey',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Суперкупа на Турция',
		countryBg: 'Турция',
		rank: 12,
		countryAbreviation: 'TR',
	},
	{
		tournamentId: 96,
		tournamentName: 'Taça de Portugal',
		country: 'Portugal',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Купа на Португалия',
		countryBg: 'Португалия',
		rank: 12,
		countryAbreviation: 'PT',
	},
	{
		tournamentId: 97,
		tournamentName: 'Taça da Liga',
		country: 'Portugal',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Купа на Лигата',
		countryBg: 'Португалия',
		rank: 12,
		countryAbreviation: 'PT',
	},
	{
		tournamentId: 550,
		tournamentName: 'Super Cup',
		country: 'Portugal',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Суперкупа на Португалия',
		countryBg: 'Португалия',
		rank: 12,
		countryAbreviation: 'PT',
	},
	{
		tournamentId: 185,
		tournamentName: 'League Cup',
		country: 'Scotland',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Купа на Лигата',
		countryBg: 'Шотландия',
		rank: 13,
		countryAbreviation: 'SCT',
	},
	{
		tournamentId: 181,
		tournamentName: 'FA Cup',
		country: 'Scotland',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'ФА Къп',
		countryBg: 'Шотландия',
		rank: 13,
		countryAbreviation: 'SCT',
	},
	{
		tournamentId: 555,
		tournamentName: 'Supercupa',
		country: 'Romania',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Суперкупа на Румъния',
		countryBg: 'Румъния',
		rank: 13,
		countryAbreviation: 'RO',
	},
	{
		tournamentId: 285,
		tournamentName: 'Cupa României',
		country: 'Romania',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Купа на Румъния',
		countryBg: 'Румъния',
		rank: 13,
		countryAbreviation: 'RO',
	},
	{
		tournamentId: 732,
		tournamentName: 'Cup',
		country: 'Serbia',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Купа на Сърбия',
		countryBg: 'Сърбия',
		rank: 13,
		countryAbreviation: 'RS',
	},
	{
		tournamentId: 756,
		tournamentName: 'Cup',
		country: 'North Macedonia',
		format: TournamentFormats.CUP,
		tournamentNameBg: 'Купа на Македония',
		countryBg: 'С. Македония',
		rank: 13,
		countryAbreviation: 'MK',
	},
	{
		tournamentId: 307,
		tournamentName: 'Pro League',
		country: 'Saudi-Arabia',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Про Лига',
		countryBg: 'Саудитска Арабия',
		rank: 14,
		countryAbreviation: 'SA',
	},
	{
		tournamentId: 175,
		tournamentName: 'Third League - Northeast',
		country: 'Bulgaria',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Трета лига - Североизточна',
		countryBg: 'България',
		rank: 13,
		countryAbreviation: 'BG',
	},
	{
		tournamentId: 177,
		tournamentName: 'Third League - Northeast',
		country: 'Bulgaria',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Трета лига - Югоизточна',
		countryBg: 'България',
		rank: 13,
		countryAbreviation: 'BG',
	},
	{
		tournamentId: 178,
		tournamentName: 'Third League - Northeast',
		country: 'Bulgaria',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Трета лига - Югозападна',
		countryBg: 'България',
		rank: 13,
		countryAbreviation: 'BG',
	},
	{
		tournamentId: 176,
		tournamentName: 'Third League - Northeast',
		country: 'Bulgaria',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Трета лига - Северозападна',
		countryBg: 'България',
		rank: 13,
		countryAbreviation: 'BG',
	},
	{
		tournamentId: 408,
		tournamentName: 'Premiership',
		country: 'Northern-Ireland',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Премиършип',
		countryBg: 'Северна Ирландия',
		rank: 14,
		countryAbreviation: 'NI',
	},
	{
		tournamentId: 244,
		tournamentName: 'Veikkausliiga',
		country: 'Finland',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Вейкауслига',
		countryBg: 'Финландия',
		rank: 14,
		countryAbreviation: 'FI',
	},
	{
		tournamentId: 329,
		tournamentName: 'Meistriliiga',
		country: 'Estonia',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Майстрилига',
		countryBg: 'Естония',
		rank: 14,
		countryAbreviation: 'EE',
	},
	{
		tournamentId: 362,
		tournamentName: 'A Lyga',
		country: 'Lithuania',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'А Лига',
		countryBg: 'Литва',
		rank: 14,
		countryAbreviation: 'LT',
	},
	{
		tournamentId: 394,
		tournamentName: 'Super Liga',
		country: 'Moldova',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Супер Лига',
		countryBg: 'Молдова',
		rank: 14,
		countryAbreviation: 'MD',
	},
	{
		tournamentId: 393,
		tournamentName: 'Premier League',
		country: 'Malta',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Висша Лига',
		countryBg: 'Малта',
		rank: 14,
		countryAbreviation: 'MT',
	},
	{
		tournamentId: 332,
		tournamentName: 'Super Liga',
		country: 'Slovakia',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Супер Лига',
		countryBg: 'Словакия',
		rank: 14,
		countryAbreviation: 'SK',
	},
	{
		tournamentId: 373,
		tournamentName: '1. SNL',
		country: 'Slovenia',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Първа Лига',
		countryBg: 'Словения',
		rank: 14,
		countryAbreviation: 'SI',
	},
	{
		tournamentId: 261,
		tournamentName: 'National Division',
		country: 'Luxembourg',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Национална Лига',
		countryBg: 'Люксембург',
		rank: 14,
		countryAbreviation: 'LU',
	},
	{
		tournamentId: 315,
		tournamentName: 'Premijer Liga',
		country: 'Bosnia',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Висша Лига',
		countryBg: 'Босна',
		rank: 14,
		countryAbreviation: 'LU',
	},
	{
		tournamentId: 371,
		tournamentName: 'First League',
		country: 'Macedonia',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Първа Лига',
		countryBg: 'С. Македония',
		rank: 14,
		countryAbreviation: 'MK',
	},
	{
		tournamentId: 355,
		tournamentName: 'First League',
		country: 'Montenegro',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Първа Лига',
		countryBg: 'Черна Гора',
		rank: 14,
		countryAbreviation: 'ME',
	},
	{
		tournamentId: 311,
		tournamentName: '1st Division',
		country: 'Albania',
		format: TournamentFormats.LEAGUE,
		tournamentNameBg: 'Суперлига',
		countryBg: 'Албания',
		rank: 14,
		countryAbreviation: 'AL',
	},
	{
		tournamentId: 480,
		tournamentName: 'Olympics Men',
		country: 'World',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Олимпийски игри',
		countryBg: 'Международни',
		rank: 3,
		countryAbreviation: 'WORLD',
	},
	{
		tournamentId: 524,
		tournamentName: 'Olympics Women',
		country: 'World',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Олимпийски игри(жени)',
		countryBg: 'Международни',
		rank: 3,
		countryAbreviation: 'WORLD',
	},
	{
		tournamentId: 17,
		tournamentName: 'AFC Champions League',
		country: 'World',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Шампионска Лига (Азия)',
		countryBg: 'Международни',
		rank: 15,
		countryAbreviation: 'WORLD',
	},
	{
		tournamentId: 18,
		tournamentName: 'AFC Cup',
		country: 'Asia',
		format: TournamentFormats.GROUPS_AND_KNOCKOUT,
		tournamentNameBg: 'Купа на Азия',
		countryBg: 'Международни',
		rank: 15,
		countryAbreviation: 'EU'
	},
];

export const findTournament = (tournamentId) => TOURNAMENTS.find((current) => current.tournamentId === +tournamentId);
export const getTournamentFormat = tournamentId => {
	return findTournament(tournamentId)?.format;
};

const DO_NOT_SHOW_TOURNAMENTS = [4, 1];

export const LEAGUE_TOURNAMENTS = TOURNAMENTS.filter(
	t =>
		(t.format === TournamentFormats.LEAGUE ||
    t.format === TournamentFormats.GROUPS_AND_KNOCKOUT) &&
    !DO_NOT_SHOW_TOURNAMENTS.includes(t.tournamentId)
);

const TOP_TOURNAMENTS_IDS = [39, 172, 135, 140, 78, 61];
export const TOP_TOURNAMENTS = TOURNAMENTS.filter(
	t => TOP_TOURNAMENTS_IDS.includes(t.tournamentId)
);

const INTERNATIONAL_TOURNAMENTS_IDS = [4, 960, 5, 6, 9, 6, 7];
export const INTERNATIONAL_TOURNAMENTS = TOURNAMENTS.filter(
	t => INTERNATIONAL_TOURNAMENTS_IDS.includes(t.tournamentId)
);

const CLUB_TOURNAMENTS_IDS = [2, 3, 848, 11, 13];
export const CLUB_TOURNAMENTS = TOURNAMENTS.filter(
	t => CLUB_TOURNAMENTS_IDS.includes(t.tournamentId)
);

export const TOURNAMENTS_BY_COUNTRIES = LEAGUE_TOURNAMENTS.filter(
	t =>
		!TOP_TOURNAMENTS_IDS.includes(t.tournamentId) && 
		!INTERNATIONAL_TOURNAMENTS_IDS.includes(t.tournamentId) && 
		!CLUB_TOURNAMENTS_IDS.includes(t.tournamentId)
).reduce((acc, currentTournament) => {
	const countryGroup = acc.find(
		currentGroup => currentGroup.country === currentTournament.countryBg
	);
	if (countryGroup) {
		countryGroup.tournaments.push(currentTournament);
	} else {
		acc.push({
			country: currentTournament.countryBg,
			tournaments: [currentTournament],
		});
	}

	return acc;
}, []).sort((prev, next) => {
	if (prev.country < next.country) {
		return -1;
	}

	if (prev.country > next.country) {
		return 1;
	}

	return 0;
});

export const OTHER_TOURNAMENTS_BY_COUNTRIES = [
	{
		country: 'Клубни',
		tournaments: CLUB_TOURNAMENTS,
	},
	{
		country: 'Международни',
		tournaments: INTERNATIONAL_TOURNAMENTS,
	},
	...TOURNAMENTS_BY_COUNTRIES
];

export const TOURNAMENTS_BY_RANK = TOURNAMENTS.sort((prev, next) => {
	if (prev.rank < next.rank) {
		return -1;
	}
	if (prev.rank > next.rank) {
		return 1;
	}
	return 0;
}).filter(
	tournament =>
		tournament.format === TournamentFormats.LEAGUE ||
    tournament.format === TournamentFormats.GROUPS_AND_KNOCKOUT
).sort((prev, next) => {
	if (prev.countryBg < next.countryBg) {
		return -1;
	}

	if (prev.countryBg > next.countryBg) {
		return 1;
	}

	return 0;
});

export const statusTranslte = {
	// In play
	'HT': 'полувр.',
	'BT': 'поч.',
	'P': 'дузпи',
	'SUSP': 'прекр.',
	'INT': 'прекр.',
	'LIVE': '-',
	'ET': 'прод.',
	// Finished
	'FT': <span title="Приключил">прикл.</span>,
	'AET': <span title="Приключил след продължения">прикл.<br />с прод.</span>,
	'PEN': <span title="Приключил след дузпи">прикл.<br />с дузпи</span>,
	'PST': <span title="Отложен">отл.</span>,
	'CANC': <span title="Отложен">отл.</span>,
	'ABD': <span title="Отложен">отл.</span>,
	'AWD': <span title="Няма данни">няма данни</span>,
	'WO': <span title="Служебно приключил">служб.<br />прикл.</span>
};

export const finishedStatuses = ['FT', 'AET', 'PEN', 'PST', 'CANC', 'ABD', 'AWD', 'WO'];

export const inProgressStatuses = ['HT', 'BT', 'P', 'INT', 'LIVE'];

export const comparisonLabels = [
	'Форма',
	'Атака',
	'Защита',
	'Възможност за изненади',
	'Директни срещи',
	'Голове',
	'Общо'
];

export const tournamentsWithLiveStats = [4, 960, 39, 135, 172, 140, 40, 78, 61, 1, 2, 3, 848, 32, 531, 45, 48, 137, 66, 143, 528, 526, 529, 531, 5];

export const shortWeekDays = {
	Mon: 'ПН',
	Tue: 'ВТ',
	Wed: 'СР',
	Thu: 'ЧТ',
	Fri: 'ПТ',
	Sat: 'СБ',
	Sun: 'НД',
};

export const gamePageFilters = [
	{
		label: 'Начало',
		key: 'main'
	},
	{
		label: 'Класиране',
		key: 'standings'
	},
	{
		label: 'Награди',
		key: 'prizes'
	},
	{
		label: 'Правила',
		key: 'rules'
	},
	{
		label: 'Oще',
		key: 'more'
	}
];

export const quizesPageFilters = [
	{
		label: 'Въпроси',
		key: 'questions'
	},
	{
		label: 'Лога',
		key: 'logos'
	},
	{
		label: 'Класиране',
		key: 'standings'
	},
];

export const factsTournaments = [
	4, // EURO
	9, // Copa America
	39,
	45,
	46,
	48,
	40,
	528,
	41,
	42,
	43, // England
	172,
	174,
	656,
	274,
	275,
	277,
	278,
	276, // Bulgaria
	78,
	81,
	529,
	79, // Germany
	197,
	199, // Greece
	2,
	531,
	960,
	3,
	848,
	32,
	5,
	1, // Europe and World
	556,
	140,
	143,
	141, // Spain
	547,
	135,
	137,
	136, // Italy
	88,
	543,
	90, // Netherlands
	94,
	96,
	97,
	550, // Portugal
	283,
	555,
	285, // Romania
	756,
	371, // Macedonia
	286,
	732, // Serbia
	203,
	206,
	551, // Turkey
	61,
	66,
	526,
	62, // France
	179,
	185,
	181 // Scotland
];

export const usersStatsFilters = [
	{
		label: 'Месец',
		achievements: 'месеца',
		value: 'month',
		showEndOfGamePeriod: () => {
		  const now = new Date();
			const dayOfMonth = now.getDate(); // getDate() returns the day of the month (1-31)
			const hour = now.getHours(); // getHours() returns the hour (0-23)

			// Check if it's the first day of the month and after 8 AM
			return dayOfMonth === 1 && hour >= 8;
		}
	},
	{
		label: 'Седмица',
		achievements: 'седмицата',
		value: 'week',
		showEndOfGamePeriod: () => {
			const now = new Date();
			const dayOfWeek = now.getDay(); // getDay() returns 0 for Sunday, 1 for Monday, etc.
			const hour = now.getHours(); // getHours() returns the hour (0-23)
		
			// Check if it's Monday and after 8 AM
			// Remember, 8 AM is hour 8 in 24-hour format
			return dayOfWeek === 1 && hour >= 8;
		}
	},
];