const SET_COINS = 'SET_COINS';

const setCoins = (coins) => ({
	type: SET_COINS,
	payload: coins,
});

export {
	SET_COINS,
	setCoins,
};