exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avatar-js": () => import("./../../../src/pages/avatar.js" /* webpackChunkName: "component---src-pages-avatar-js" */),
  "component---src-pages-biskvitki-js": () => import("./../../../src/pages/biskvitki.js" /* webpackChunkName: "component---src-pages-biskvitki-js" */),
  "component---src-pages-evropeisko-parvenstvo-js": () => import("./../../../src/pages/evropeisko-parvenstvo.js" /* webpackChunkName: "component---src-pages-evropeisko-parvenstvo-js" */),
  "component---src-pages-igra-s-prognozi-js": () => import("./../../../src/pages/igra-s-prognozi.js" /* webpackChunkName: "component---src-pages-igra-s-prognozi-js" */),
  "component---src-pages-igrachi-[username]-js": () => import("./../../../src/pages/igrachi/[username].js" /* webpackChunkName: "component---src-pages-igrachi-[username]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klasirane-js": () => import("./../../../src/pages/klasirane.js" /* webpackChunkName: "component---src-pages-klasirane-js" */),
  "component---src-pages-kontakti-js": () => import("./../../../src/pages/kontakti.js" /* webpackChunkName: "component---src-pages-kontakti-js" */),
  "component---src-pages-kuizove-js": () => import("./../../../src/pages/kuizove.js" /* webpackChunkName: "component---src-pages-kuizove-js" */),
  "component---src-pages-kuizove-quiz-id-js": () => import("./../../../src/pages/kuizove/[quizId].js" /* webpackChunkName: "component---src-pages-kuizove-quiz-id-js" */),
  "component---src-pages-machove-fixture-id-js": () => import("./../../../src/pages/machove/[fixtureId].js" /* webpackChunkName: "component---src-pages-machove-fixture-id-js" */),
  "component---src-pages-machove-js": () => import("./../../../src/pages/machove.js" /* webpackChunkName: "component---src-pages-machove-js" */),
  "component---src-pages-novini-js": () => import("./../../../src/pages/novini.js" /* webpackChunkName: "component---src-pages-novini-js" */),
  "component---src-pages-obshti-usloviq-js": () => import("./../../../src/pages/obshti-usloviq.js" /* webpackChunkName: "component---src-pages-obshti-usloviq-js" */),
  "component---src-pages-otbori-team-id-js": () => import("./../../../src/pages/otbori/[teamId].js" /* webpackChunkName: "component---src-pages-otbori-team-id-js" */),
  "component---src-pages-poveritelnost-js": () => import("./../../../src/pages/poveritelnost.js" /* webpackChunkName: "component---src-pages-poveritelnost-js" */),
  "component---src-pages-pravila-na-igrata-js": () => import("./../../../src/pages/pravila-na-igrata.js" /* webpackChunkName: "component---src-pages-pravila-na-igrata-js" */),
  "component---src-pages-statii-js": () => import("./../../../src/pages/statii.js" /* webpackChunkName: "component---src-pages-statii-js" */),
  "component---src-pages-za-nas-js": () => import("./../../../src/pages/za-nas.js" /* webpackChunkName: "component---src-pages-za-nas-js" */),
  "component---src-templates-article-template-article-template-js": () => import("./../../../src/templates/article-template/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-article-template-js" */)
}

