/* eslint-disable max-len */
import { toast } from 'react-toastify';
import httpProvider from '../../utils/httpProvider';
import {
	GET_USERS_GAME_DATA,
	GET_PROFILE_DATA,
	REGISTER_USER,
	setProfileData,
	startLoadingUsers,
	stopLoadingUsers,
	startLoginLoading,
	stopLoginLoading,
	setLoggedUser,
	GET_LOGGED_USER,
	DELETE_USER,
	CHANGE_USER_PASSWORD,
	setUsersGameData,
	startLoadingUsersGameData,
	stopLoadingUsersGameData,
	TAKE_MISSION_COINS,
	setUserMissions,
} from '../actions/users.actions';
import { logout, setAuthForm } from '../actions/auth.actions';
import { navigate } from 'gatsby';
import { setCoins } from '../actions/coins.actions';

const getUsersGameDataMiddleware = ({ dispatch, getState }) => next => async action => {
	if (action.type === GET_USERS_GAME_DATA) {
		dispatch(startLoadingUsersGameData());

		try {
			const userId = getState().users?.loggedUser?.id;
			const query = userId ? `?userId=${userId}` : '';
			const response = await httpProvider.get(`users/game${query}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			});

			if (response.data) {
				dispatch(setUsersGameData(response.data));
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingUsersGameData());
		}
	} else {
		return next(action);
	}
};

const takeUserMissionCoinsMiddleware  = ({ dispatch, getState }) => next => async action => {
	if (action.type === TAKE_MISSION_COINS) {

		try {
			const userId = getState().users?.loggedUser?.id;
			const coins = getState().coins.coins;
			const response = await httpProvider.put(`users/${userId}/missions`,
				{ mission: action.payload.mission },
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				});

			if (response.data) {
				dispatch(setUserMissions(action.payload));
				toast.success(`Успешно взехте ${action.payload.coins} койнс!`);
				dispatch(setCoins(coins + action.payload.coins));
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			// no loading
		}
	} else {
		return next(action);
	}
};

const registerUserMiddleware =
  ({ dispatch }) =>
  	next =>
  		async action => {
  			if (action.type === REGISTER_USER) {
  				dispatch(startLoadingUsers());

  				try {
  					const response = await httpProvider.post('users', action.payload);

  					if (response.data) {
  						toast.success('Успешна регистрация!', {
  							autoClose: false,
  						});
  						toast.info(`Изпратихме Ви имейл с линк за верификация. Моля, проверете имейла си (${action.payload.email}) и потвърдете вашият профил. Моля проверете и папка spam.`, {
  							autoClose: false,
  						});
  					}

  					dispatch(setAuthForm(null));
  				} catch (err) {
  					if (err.code === 500) {
  						toast.error('Нещо се обърка! Моля опитайте по-късно.');
  					} else {
  						toast.error(err.response.data.message);
  					}
  				} finally { 
  					dispatch(stopLoadingUsers());
  				}
  			} else {
  				return next(action);
  			}
  		};

const getLoggedUserMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_LOGGED_USER) {
		dispatch(startLoginLoading());
			
		try {
			const response  = await httpProvider.get(
				'auth',
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				}
			);
				
			if (response.data) {
				dispatch(setLoggedUser(response.data));
				dispatch(setCoins(response.data.coins));
			}
		} catch (err) {
			// TODO - wtf to do here
		} finally {
			dispatch(stopLoginLoading());
		}
	} else {
		return next(action);
	}
};

const deleteUserMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === DELETE_USER) {
		dispatch(startLoadingUsers());
			
		try {
			const response  = await httpProvider.remove(`users/${action.payload.userId}`, {}, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			});
				
			if (response.status === 200) {

				toast.success('Успешно изтрихте своя профил!');
				navigate('/');
				dispatch(logout());
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingUsers());
		}
	} else {
		return next(action);
	}
};

const changeUserPasswordMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === CHANGE_USER_PASSWORD) {
		dispatch(startLoadingUsers());

		try {
			const response  = await httpProvider.post(
				`users/${action.payload.userId}/passwords`,
				action.payload.passwords,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				}
			);
				
			if (response.data) {
				dispatch(logout());
				toast.success('Успешно променихте паролата си!');
			}
		} catch (err) {
			if (err.code === 500) {
				toast.error('Нещо се обърка! Моля опитайте по-късно.');
			} else {
				toast.error(err.response.data.message);
			}
		} finally {
			dispatch(stopLoadingUsers());
		}
	} else {
		return next(action);
	}
};

const getProfileDataMiddleware =
  ({ dispatch }) =>
  	next =>
  		async action => {
  			if (action.type === GET_PROFILE_DATA) {
  				dispatch(startLoadingUsers());

  				try {
  					const response = 
							await httpProvider.get(`users/${action.payload.username}`);

  					if (response.data) {
  						dispatch(setProfileData(response.data));
  					}
  				} catch (err) {
  					if (err.code === 500) {
  						toast.error('Нещо се обърка! Моля опитайте по-късно.');
  					} else {
  						toast.error('Такъв профил не съществува!');
  					}
  				} finally {
  					dispatch(stopLoadingUsers());
  				}
  			} else {
  				return next(action);
  			}
  		};

export {
	getUsersGameDataMiddleware,
	registerUserMiddleware,
	getProfileDataMiddleware,
	getLoggedUserMiddleware,
	deleteUserMiddleware,
	changeUserPasswordMiddleware,
	takeUserMissionCoinsMiddleware
};
