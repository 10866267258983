/* eslint-disable max-len */
import { toast } from 'react-toastify';
import httpProvider from '../../utils/httpProvider';
import { BUY_ITEM, GET_USER_AVATAR_DATA, SAVE_USED_AVATAR_ITEMS, setUserAvatarData, startLoadingBuyItem, startLoadingSaveUsedAvatarItems, startLoadingUserAvatarData, stopLoadingBuyItem, stopLoadingSaveUsedAvatarItems, stopLoadingUserAvatarData } from '../actions/avatar.actions';
import { setCoins } from '../actions/coins.actions';

const getUserAvatarMiddleware = ({ dispatch }) => next => async action => {
	if (action.type === GET_USER_AVATAR_DATA) {
		dispatch(startLoadingUserAvatarData());

		try {
			const response = await httpProvider.get(`avatar-items/${action.payload}`, 
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				}
			);

			if (response.data) {
				dispatch(setUserAvatarData(response.data));
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingUserAvatarData());
		}
	} else {
		return next(action);
	}
};

const buyAvatarItemMiddleware = ({ dispatch, getState }) => next => async action => {
	if (action.type === BUY_ITEM) {
		const coins = getState().coins.coins;
		if (coins < action.payload.price) {
			toast.info('Нямате достатъчно пари за тази покупка!');
			return;
		}

		dispatch(startLoadingBuyItem());
		try {
			const response = await httpProvider.put(`avatar-items/${action.payload.userId}`, 
				{
					item: action.payload.item,
					type: action.payload.type
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				}
			);

			if (response.data) {
				dispatch(setUserAvatarData(response.data));
				dispatch(setCoins(coins - action.payload.price));
				toast.success('Успешно закупихте артикула!');
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingBuyItem());
		}
	} else {
		return next(action);
	}
};

const saveUsedAvatarItemsMiddleware = ({ dispatch, getState }) => next => async action => {
	if (action.type === SAVE_USED_AVATAR_ITEMS) {
		dispatch(startLoadingSaveUsedAvatarItems());
		try {
			const userId = getState().users.loggedUser.id;
			const response = await httpProvider.post(`avatar-items/${userId}`, 
				action.payload,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				}
			);

			if (response.data) {
				dispatch(setUserAvatarData(response.data));
				toast.success('Промените бяха запазени успешно!');
			}
		} catch (err) {
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingSaveUsedAvatarItems());
		}
	} else {
		return next(action);
	}
};

export {
	getUserAvatarMiddleware,
	buyAvatarItemMiddleware,
	saveUsedAvatarItemsMiddleware
};
