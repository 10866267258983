import {
	FIXTURE_DETAILS_READY,
	RESET_FIXTURE_DETAILS,
	GET_FIXTURE_DETAILS,
	START_LOADING_FIXTURE_DETAILS,
	STOP_LOADING_FIXTURE_DETAILS,
	START_LOADING_VOTING,
	STOP_LOADING_VOTING,
} from '../actions/fixture-details.actions';

const initialState = {
	isLoading: true,
	isVotingLoading: false,
};

const fixtureDetailsReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_FIXTURE_DETAILS:
		return state;
	case FIXTURE_DETAILS_READY:
		return {
			...state,
			...action.payload 
		};
	case RESET_FIXTURE_DETAILS:
		return initialState;
	case START_LOADING_VOTING:
		return {
			...state,
			isVotingLoading: true
		};
	case STOP_LOADING_VOTING:
		return {
			...state,
			isVotingLoading: false
		};
	case START_LOADING_FIXTURE_DETAILS:
		return {
			...state,
			isLoading: true
		};
	case STOP_LOADING_FIXTURE_DETAILS:
		return {
			...state,
			isLoading: false
		};
	default:
		return state;
	}
};

export default fixtureDetailsReducer;